export function base64ToHex(str: string) {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += (hex.length === 2 ? hex : '0' + hex);
  }
  return result;
}

export function u8aToHex(u8a: number[] | Uint8Array) {
  return Array.from(u8a, i => i.toString(16).padStart(2, '0')).join('');
}

export function hexTou8a(hex: string) {
  return new Uint8Array(hex.match(/.{1,2}/g)?.map(byte => parseInt(byte, 16)) || []);
}

export const toShortAddress = (address: string, maxLength = 16) => {
  const tmpArr = address.split('.');
  const halfLength = Math.floor(maxLength / 2);
  const realAccount = tmpArr[0];
  if (realAccount.length <= maxLength) {
    return address;
  }
  return realAccount.substr(0, halfLength) + '...' + realAccount.substr(-halfLength) + (tmpArr[1] ? '.' + tmpArr[1] :  '');
}

export function isNumber(value: any) {
  const reg = /^[0-9]+\.?[0-9]*$/;
  return reg.test(value);
}

export function beautify(str = ''): string {
  const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  str = str.replace(reg, '$1,');
  return str.replace(/(\.[0-9]*[1-9]+)(0)*/, '$1');
}

export function toOrderStatus(status: number) {
  const statusObj: any = {
    0: {
      label: 'Submitted',
      color: 'teal'
    },
    1: {
      label: 'PartialFilled',
      color: 'green'
    },
    2: {
      label: 'Filled',
      color: 'green'
    },
    3: {
      label: 'Canceled',
      color: 'gray'
    }
  }

  return statusObj[status] || {
    label: 'Unknown',
    color: 'gray'
  }
}