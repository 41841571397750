import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { GlobalStyleProps } from '@chakra-ui/theme-tools';

const themeConfig = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false
  },
  colors: {
    pngLinear: 'linear-gradient(to right, #6C9EFF, #09CA65 35%, #7BD2A2 70%, #FBFE7F)'
  },
  styles: {
    global: (props: GlobalStyleProps) => ({
      body: {
        bg: mode('#f6f7fa', '#1b1a1c')(props),
        color: mode('#1b1a1c', 'white')(props),
      },
      '.png-gray': {
        color: mode('#868099', '#a6a0bb')(props)
      },
    })
  },
  components: {
    Container: {
      baseStyle: {
        maxW: 'full'
      }
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg'
      }
    },
    Modal: {
      baseStyle: (props: GlobalStyleProps) => ({
        overlay: {
          bg: mode('blackAlpha.600', 'blackAlpha.500')(props),
        },
        dialog: {
          bg: mode('white', '#1b1a1c')(props),
        }
      })
    },
  }
}

export const theme = extendTheme(themeConfig);