import React, { useState } from 'react';
import useSWR from 'swr';

import {
  Grid,
  Flex,
  GridItem,
  Divider,
  Box
} from '@chakra-ui/react';

import { Market } from './Market';
import { Book } from './Book';
import { Chart } from './Chart';
import { Panel } from './Panel';
import { History } from './History';
import { OrderSide } from 'types';

import { useCommonStore } from 'stores';

export const Trade: React.FC = () => {

  const [defaultPrice, setDefaultPrice] = useState<string>();
  const [defaultSize, setDefaultSize] = useState<string>();
  const [defaultSide, setDefaultSide] = useState<OrderSide>();

  const [newTrade, setNewTrade] = useState<any>();

  const { common } = useCommonStore();

  const { data: markets } = useSWR(`markets?symbol=BTCUSDT`, { refreshInterval: 1000 });

  const { data: contract } = useSWR(
    common?.account ? `contract?symbol=${'BTCUSDT'}&address=${common.account.address}` : null,
    { refreshInterval: 1000 }
  );

  const onOrderClick = ([price, size, side]: [string, string, OrderSide]) => {
    setDefaultPrice(price);
    setDefaultSize(size);
    setDefaultSide(side);
  }

  return (
    <>
      <Market data={markets} newTrade={newTrade} />
      <Divider />
      <Grid templateColumns="repeat(16, 1fr)">
        <GridItem colSpan={3} borderRightWidth={1} borderColor="whiteAlpha.200">
          <Panel defaultPrice={defaultPrice} defaultSize={defaultSize} defaultSide={defaultSide} contract={contract} />
        </GridItem>
        <GridItem colSpan={3} borderRightWidth={1} borderColor="whiteAlpha.200">
          <Book onOrderClick={onOrderClick} onNewTrade={t => setNewTrade(t)} markets={markets} />
        </GridItem>
        <GridItem colSpan={10}>
          <Flex flexDirection="column" h="calc(100vh - 121px)">
            <Box flex={1}>
              <Chart />
            </Box>
            <Box borderTopWidth={1} h="30vh" borderColor="whiteAlpha.200">
              <History contract={contract} markets={markets} />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
}