export enum OrderSide {
  Buy = 1,
  Sell = 2
}

export enum OrderStatus {
  Submitted,
  PartialFilled,
  Filled,
  PartialCanceled,
  Canceled
}