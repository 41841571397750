import React, { useState } from 'react';

import {
  Box,
  Heading,
  Center,
  Text,
  HStack,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import { AddIcon, MinusIcon } from '@chakra-ui/icons';

type LaverageProps = {
  defaultLaverage?: number;
  value: number;
  onChange: (v: number) => void;
  max?: number;
}

export const Laverage: React.FC<LaverageProps> = ({ value, onChange, max = 20 }) => {

  return (
    <>
      <HStack className="png-gray" fontSize="sm">
        <Text>Laverage</Text>
        <Text opacity={.7} fontSize="xs">Up to 20x</Text>
      </HStack>
      <HStack mt={3}>
        <HStack flex={1} bg="whiteAlpha.100" borderRadius="lg">
          <Button size="sm" onClick={() => value > 1 ? onChange(value - 1) : null} disabled={value <= 1}>
            <MinusIcon boxSize={3} />
          </Button>
          <Center flex={1} p="7px" borderRadius="lg">
            <Heading fontSize="md">{value}</Heading>
          </Center>
          <Button size="sm" onClick={() => value < max ? onChange(value +1) : null} disabled={value >= max}>
            <AddIcon boxSize={3} />
          </Button>
        </HStack>
        <Button size="sm" onClick={() => onChange(2)}>2x</Button>
        <Button size="sm" onClick={() => onChange(5)}>5x</Button>
        <Button size="sm" onClick={() => onChange(10)}>10x</Button>
      </HStack>
      <Box mt={2}>
        <Slider min={1} max={20} h="5px" value={value} onChange={v => onChange(v)}>
          <SliderTrack>
            <SliderFilledTrack bg="pngLinear" opacity={.8} />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </>
  )
}