import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import axios from 'axios';

import {
  Container,
  Flex,
  HStack,
  Box,
  Button,
  Divider,
  Image,
  Spinner,
  useToast,
  useBoolean,
  Link
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { ConnectWalletModal } from 'components';
import { chainConfig } from 'config';
import { base64ToHex, toShortAddress } from 'utils';
import { useCommonStore } from 'stores';

import logo from 'assets/logo-dark.png';

const NavBtn = styled(Button)`
  background: transparent;
  color: #A6A0BB;
  &:hover {
    color: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, .06);
  }
  &[aria-selected="true"] {
    color: rgba(255, 255, 255, 1);
    background: #000;
  }
`;

export const Header: React.FC = () => {

  const location = useLocation();

  const locationPath = location.pathname;

  const [accounts, setAccounts] = useState<any[]>();

  const [connectWalletModalOpen, setConnectWalletModalOpen] = useBoolean();
  const [isInitializing, setIsInitializing] = useBoolean();

  const { common, updateWallet, clearAccount } = useCommonStore();

  useEffect(() => {

    setIsInitializing.on();

    setTimeout(() => {
      const offlineSigner = window.getOfflineSigner?.(chainConfig.chainId);
      if (offlineSigner) {
        offlineSigner.getAccounts().then((accounts: any) => {
          setAccounts(accounts);
        });
      }
      setIsInitializing.off();
    }, 500);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (accounts?.length) {
      updateWallet(accounts[0]);
    }

  }, [accounts]);

  useEffect(() => {
    if (!common.account || !common.wallet) {
      return;
    }
    if (common.account && common.account.address !== common.wallet.address) {
      clearAccount();
    }
  }, [common]);

  return (
    <>
      <Container>
        <Flex pt={3} pb={3} justifyContent="space-between">
          <HStack spacing={3}>
            <Box maxW="140px">
              <Image src={logo} w="100%" h="auto" />
            </Box>
            <Divider orientation="vertical" />
            <NavBtn size="sm">
              Testnet <ChevronDownIcon ml={1} />
            </NavBtn>
            <Divider orientation="vertical" />
            <NavBtn size="sm">
              English <ChevronDownIcon ml={1} />
            </NavBtn>
            <Divider orientation="vertical" />
            <HStack spacing={4} pl={2}>
              <NavBtn size="sm" aria-selected={/^\/trade/i.test(locationPath)}>Trade</NavBtn>
              <NavBtn size="sm" as={Link} href="https://explorer.png.fi" isExternal>
                Explorer <ExternalLinkIcon ml={1} />
              </NavBtn>
            </HStack>
          </HStack>
          {
            isInitializing ?
              <Button size="sm"><Spinner size="sm" /></Button> :
              common.wallet ?
                <Button size="sm">
                  {toShortAddress(common.wallet.address)}
                  <ChevronDownIcon ml={1} />
                </Button> :
                <Button size="sm" onClick={setConnectWalletModalOpen.on}>
                  Connect Wallet
                </Button>
          }

        </Flex>
      </Container>
      <ConnectWalletModal isOpen={connectWalletModalOpen} onClose={setConnectWalletModalOpen.off} />
    </>
  );
}