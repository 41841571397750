import React from 'react';

import {
  Modal, 
  ModalContent, 
  ModalOverlay, 
} from '@chakra-ui/react';

export type BaseModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  isCentered?: boolean;
  children?: React.ReactElement|React.ReactElement[];
  maxW?: number|string;
}

export const BaseModal: React.FC<BaseModalProps> = (props) => {
  const { isOpen, onClose, maxW, children, isCentered } = props;

  return (
    <Modal isOpen={isOpen} isCentered={ isCentered !== undefined ? isCentered : true } 
      motionPreset="slideInBottom" onClose={onClose}>
      <ModalOverlay className="backdrop-blur transition" />
      <ModalContent ml={3} mr={3} maxW={maxW} borderRadius="xl">
        <>{ children }</>
      </ModalContent>
    </Modal>
  )
}
