import React, { useState, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import dayjs from 'dayjs';
import releativeTime from 'dayjs/plugin/relativeTime';

import {
  SimpleGrid,
  Text,
  Center,
  Box,
  Heading,
  Spinner,
  List,
  Link,
  Flex,
  Tooltip,
  useBoolean,
  useColorModeValue
} from '@chakra-ui/react';
import { DecimalUtil } from 'utils';
import { OrderSide } from 'types';
import { useCommonStore } from 'stores';

type BookRowProps = {
  data: [string, string, string, boolean],
  max?: number;
  side: OrderSide;
  onClick: ([price, size, side]: [string, string, OrderSide]) => void;
};

type TradeRowProps = {
  data: {
    size: string;
    price: string;
    timestamp: number;
    side: OrderSide;
    txhash: string;
  }
};

type BookProps = {
  onOrderClick: ([price, size, side]: [string, string, OrderSide]) => void;
  onNewTrade: (trade: any) => void;
  markets: any;
}

dayjs.extend(releativeTime);

const BookRow: React.FC<BookRowProps> = ({ data: [price, size, sum, isMine], max = 0, side, onClick }) => {

  const [isHovering, setIsHovering] = useBoolean();

  const _onClick = () => onClick([price, sum, side === OrderSide.Buy ? OrderSide.Sell : OrderSide.Buy]);

  return (
    <SimpleGrid columns={3} cursor="pointer" onMouseLeave={setIsHovering.off}
      onMouseEnter={setIsHovering.on} position="relative" onClick={_onClick}>
      <Box p={1} pl={3}>
        <Text fontSize="sm" color={side === OrderSide.Sell ? 'rgb(246, 70, 93)' : 'rgb(14, 203, 129)'}>
          ${DecimalUtil.beautify(DecimalUtil.fromString(price))}
        </Text>
      </Box>
      <Center p={1}>
        <Text fontSize="sm" className="png-gray">{size}</Text>
      </Center>
      <Box p={1} pr={3} textAlign="right">
        <Text fontSize="sm" className="png-gray">{sum}</Text>
      </Box>
      {
        max ?
          <Box w={`${(sum as any * 100) / max}%`}
            bg={side === OrderSide.Sell ? 'rgb(246, 70, 93)' : 'rgb(14, 203, 129)'} opacity={.1}
            position="absolute" top={0} right={0} bottom={0} zIndex={0} /> : null
      }
      {
        isHovering ?
          <Box w="100%" bg="rgba(255, 255, 255, .1)" position="absolute" top={0} right={0} bottom={0} zIndex={1} /> : null
      }
      {
        isMine ?
          <Box boxSize="6px" position="absolute" left="2px" top="12px" borderRadius="full"
            bg={side === OrderSide.Sell ? 'rgb(246, 70, 93)' : 'rgb(14, 203, 129)'} /> : null
      }
    </SimpleGrid>
  )
}

const TradeRow: React.FC<TradeRowProps> = ({ data: { price, size, timestamp, side, txhash } }) => {
  const [isHovering, setIsHovering] = useBoolean();

  return (
    <Link isExternal href={`https://explorer.png.fi/txs/${txhash}`} _hover={{ textDecoration: 'none' }}>
      <SimpleGrid columns={3} cursor="pointer" onMouseLeave={setIsHovering.off}
        onMouseEnter={setIsHovering.on} position="relative">
        <Box p={1} pl={3}>
          <Text fontSize="sm" className="png-gray">{size}</Text>
        </Box>
        <Center p={1}>
          <Text fontSize="sm" color={side === OrderSide.Sell ? 'rgb(246, 70, 93)' : 'rgb(14, 203, 129)'}>
            ${DecimalUtil.beautify(DecimalUtil.fromString(price))}
          </Text>
        </Center>
        <Box p={1} pr={3} textAlign="right">
          <Text fontSize="sm" className="png-gray" opacity={.7} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {dayjs(timestamp).format('HH:mm:ss')}
          </Text>
        </Box>
        {
          isHovering ?
            <Box w="100%" bg="rgba(255, 255, 255, .1)" position="absolute" top={0} right={0} bottom={0} zIndex={1} /> : null
        }
      </SimpleGrid>
    </Link>
  );
}

export const Book: React.FC<BookProps> = ({ onOrderClick, onNewTrade, markets }) => {
  const gray = useColorModeValue('#868099', '#a6a0bb');
  const deepGray = useColorModeValue('rgba(0, 0, 0, .2)', 'rgba(0, 0, 0, .2)');

  const { common } = useCommonStore();

  const { data: depth } = useSWR(`depth?symbol=BTCUSDT&address=${common.account?.address}`, { refreshInterval: 1000 });
  const { data: trades } = useSWR<any[]>(`trades?symbol=BTCUSDT&limit=100`, { refreshInterval: 1000 });

  const [tab, setTab] = useState(1);

  const [totalBid, setTotalBid] = useState(0);
  const [totalAsk, setTotalAsk] = useState(0);

  const bids = useMemo(() => {
    if (!depth?.bids?.length) {
      return [];
    }

    const arr = depth.bids.slice(0, 30);

    const tmpArr = [];

    let sum = DecimalUtil.fromNumber(0);

    for (let i = 0; i < arr.length; i++) {
      const [price, size, isMineOrder] = arr[i];

      sum = sum.add(DecimalUtil.fromString(size));
      tmpArr.push([price, size, sum.toNumber(), isMineOrder * 1]);
    }

    setTotalBid(sum.toNumber());

    return tmpArr;
  }, [depth]);

  const asks = useMemo(() => {

    if (!depth?.asks?.length) {
      return [];
    }

    const sortedArr = [...depth.asks].sort((a: any, b: any) => b[0] * 1 - a[0] * 1);

    const arr = sortedArr.slice(-30);

    const tmpArr = [], sumArr = [];

    let sum = DecimalUtil.fromNumber(0);
    for (let i = arr.length; i > 0; i--) {
      const [_, size] = arr[i - 1];
      sum = sum.add(DecimalUtil.fromString(size));
      sumArr.push(sum.toNumber());
    }

    for (let i = 0; i < arr.length; i++) {
      const [price, size, isMineOrder] = arr[i];

      tmpArr.push([price, size, sumArr[sumArr.length - i - 1], isMineOrder * 1]);
    }

    setTotalAsk(sum.toNumber());

    return tmpArr;
  }, [depth]);

  const max = useMemo(() => Math.max(totalAsk, totalBid), [totalBid, totalAsk]);

  const realTrades = useMemo(() => {
    if (!trades?.length) {
      return [];
    }

    return trades.map(({ quantity, price, timestamp, is_buyer_maker, tx_hash }) => ({
      size: quantity,
      price,
      timestamp,
      is_buyer_maker,
      side: is_buyer_maker ? OrderSide.Buy : OrderSide.Sell,
      txhash: tx_hash
    }));

  }, [trades]);

  const latestTrade = useMemo(() => {
    return trades?.[0] || null;
  }, [trades]);

  useEffect(() => {
    onNewTrade(latestTrade);
  }, [latestTrade]);

  return (
    <Flex h="100%" flexDirection="column">
      <SimpleGrid columns={2} borderBottomWidth={1} borderColor="whiteAlpha.200">
        <Center
          borderColor="whiteAlpha.200"
          borderRightWidth={1} p={3}
          cursor="pointer" transition="background .3s ease"
          bg={tab === 1 ? deepGray : ''} onClick={() => setTab(1)}>
          <Text fontSize="sm" color={tab !== 1 ? gray : ''}>Book</Text>
        </Center>
        <Center p={3} cursor="pointer" bg={tab === 2 ? deepGray : ''}
          transition="background .3s ease" onClick={() => setTab(2)}>
          <Text fontSize="sm" color={tab !== 2 ? gray : ''}>Trades</Text>
        </Center>
      </SimpleGrid>
      <Flex flex={1} flexDirection="column" h="calc(100% - 200px)">
        <SimpleGrid columns={3} className="png-gray">
          <Box p={1} pl={3}>
            <Text fontSize="xs">{tab === 1 ? 'Price' : 'Size'}</Text>
          </Box>
          <Center p={1}>
            <Text fontSize="xs">{tab === 1 ? 'Size' : 'Price'}</Text>
          </Center>
          <Box p={1} pr={3} textAlign="right">
            <Text fontSize="xs">{tab === 1 ? 'Sum' : 'Time'}</Text>
          </Box>
        </SimpleGrid>
        {
          tab === 1 ?
            <Box h="calc(100vh - 193px)">
              <Box h="calc(50% - 20px)" overflowY="auto">
                <Flex flexDirection="column" justifyContent="flex-end" h="100%">
                  {
                    depth ?
                      asks.map((b, idx) => (
                        <BookRow data={b as any} key={`row-${idx}`} max={max} side={OrderSide.Sell} onClick={onOrderClick} />
                      )) :
                      <Center h="100%">
                        <Spinner thickness="3px" speed="1s" color="gray.500" />
                      </Center>
                  }
                </Flex>
              </Box>
              <Flex h="40px" borderTopWidth={1} borderBottomWidth={1} borderColor="whiteAlpha.200"
                alignItems="center" justifyContent="center">
                <Heading fontSize="lg" color={!latestTrade ? '#a6a0bb' : latestTrade.is_buyer_maker ? 'rgb(14, 203, 129)' : 'rgb(246, 70, 93)'}>
                  {
                    latestTrade ?
                      '$' + DecimalUtil.beautify(DecimalUtil.fromString(latestTrade.price)) : '-'
                  }
                </Heading>
                {
                  latestTrade && markets ? 
                  <Tooltip label="The current Mark Price">
                    <Box borderBottomWidth={1} ml={3} borderColor="#a6a0bb" pb="3px" transform="scale(.85)">
                      <Text fontSize="sm" className="png-gray" cursor="help">
                        ${DecimalUtil.beautify(DecimalUtil.fromString(markets.market_price))}
                      </Text>
                    </Box>
                  </Tooltip> : null
                }
              </Flex>
              <Box h="calc(50% - 20px)" overflowY="auto">
                <Flex flexDirection="column" h="100%">
                  {
                    depth ?
                      bids.map((b, idx) => (
                        <BookRow data={b as any} key={`row-${idx}`} max={max} side={OrderSide.Buy} onClick={onOrderClick} />
                      )) :
                      <Center h="100%">
                        <Spinner thickness="3px" speed="1s" color="gray.500" />
                      </Center>
                  }
                </Flex>
              </Box>
            </Box> :
            <Box h="calc(100vh - 193px)" overflowY="auto">
              <List>
                {
                  realTrades?.map((t, idx) => (
                    <TradeRow data={t} key={`row-${idx}`} />
                  ))
                }
              </List>
            </Box>
        }
      </Flex>
    </Flex>
  );
}