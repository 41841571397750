import React, { useState } from 'react';

import {
  Flex,
  Center,
  Text,
  Box,
  useColorModeValue
} from '@chakra-ui/react';

import { Orders } from './Orders';
import { Position } from './Position';

type TabProps = {
  index: number;
  onChange: (idx: number) => void;
}

const Tab: React.FC<TabProps> = ({ index, onChange }) => {
  const gray = useColorModeValue('#868099', '#a6a0bb');
  const deepGray = useColorModeValue('rgba(0, 0, 0, .2)', 'rgba(0, 0, 0, .2)');

  return (
    <Flex borderBottomWidth={1} borderColor="whiteAlpha.200">
      <Center
        borderColor="whiteAlpha.200"
        borderRightWidth={1}
        p={3} pl={6} pr={6}
        cursor="pointer" transition="background .3s ease"
        bg={index === 1 ? deepGray : ''} onClick={() => onChange(1)}>
        <Text fontSize="sm" color={index !== 1 ? gray : ''}>Position</Text>
      </Center>
      <Center
        borderColor="whiteAlpha.200"
        borderRightWidth={1}
        p={3} cursor="pointer" bg={index === 2 ? deepGray : ''}
        transition="background .3s ease" onClick={() => onChange(2)}>
        <Text fontSize="sm" color={index !== 2 ? gray : ''}>Orders</Text>
      </Center>
    </Flex>
  );
}

type HistoryProps = {
  contract: any;
  markets: any;
}

export const History: React.FC<HistoryProps> = ({ contract, markets }) => {

  const [tab, setTab] = useState(1);

  return (
    <>
      <Tab index={tab} onChange={idx => setTab(idx)} />
      <Box h="calc(100% - 50px)">
        {
          tab === 1 ?
            <Position contract={contract} markets={markets} /> :
            tab === 2 ?
              <Orders /> : null
        }
      </Box>
    </>
  );
}