import React from 'react';
import { SWRConfig } from 'swr';
import axios from 'axios';

import {
  Divider
} from '@chakra-ui/react';

import {
  Header,
} from 'components';

import { Outlet } from 'react-router-dom';
import { API_HOST } from 'config';

export const Root: React.FC = () => {

  return (
    <SWRConfig 
      value={{
        refreshInterval: 60 * 1000,
        fetcher: api => axios.get(`${API_HOST}/${api}`).then(res => res.data)
      }}
    >
      <Header />
      <Divider />
      <Outlet />
    </SWRConfig>
  );
}