import React from 'react';
import useSWR from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';

import {
  Grid,
  Text,
  Box,
  Center,
  Button,
  HStack,
  useBoolean,
  GridItem,
  Tag,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';

import { DecimalUtil, base64ToHex, toOrderStatus } from 'utils';
import { useCommonStore } from 'stores';
import { OrderSide, OrderStatus } from 'types';
import { chainConfig, API_HOST } from 'config';

export const Orders: React.FC = () => {
  const gray = useColorModeValue('#868099', '#a6a0bb');
  const deepGray = useColorModeValue('rgba(0, 0, 0, .2)', 'rgba(0, 0, 0, .2)');
  const toast = useToast();

  const [isCanceling, setIsCanceling] = useBoolean();

  const { common } = useCommonStore();

  const { data: orders } = useSWR(
    common?.account ? 
    `orders?address=${common.account.address}&symbol=BTCUSDT` : null, 
    { refreshInterval: 1000 }
  );

  const onCancelOrder = async (id: number) => {
    setIsCanceling.on();
    try {

      const { signature } = await window.keplr.signArbitrary(
        chainConfig.chainId,
        common.wallet?.address,
        `${id}cancel-order`
      );

      const signatureInHex = base64ToHex(signature);

      await axios.post(`${API_HOST}/cancel-order`, {
        order_id: id,
        auth_sig: signatureInHex
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        position: 'top-right',
        description: err.toString(),
        status: 'error'
      });
    }

    setIsCanceling.off();
  }

  return (
    <>
      <Grid templateColumns="repeat(8, 1fr)" borderBottomWidth={1} bg={deepGray} gap={4} borderColor="whiteAlpha.200">
        <GridItem colSpan={1} p={2}>
          <Text color={gray} fontSize="sm">Side</Text>
        </GridItem>
        <GridItem colSpan={2} p={2} textAlign="center">
          <Text color={gray} fontSize="sm">Amount</Text>
        </GridItem>
        <GridItem colSpan={2} p={2} textAlign="center">
          <Text color={gray} fontSize="sm">Price</Text>
        </GridItem>
        <GridItem colSpan={2} p={2} textAlign="center">
          <Text color={gray} fontSize="sm">Time</Text>
        </GridItem>
        <GridItem colSpan={1} p={2} textAlign="right">
          <Text color={gray} fontSize="sm">Status</Text>
        </GridItem>
      </Grid>
      <Box h="calc(100% - 40px)" overflowY="auto">
      {
        orders?.length ?
        orders.map(({ amount, filled_amount, price, timestamp, side, id, status }: any, idx: number) => (
          <Grid templateColumns="repeat(8, 1fr)" borderBottomWidth={1} gap={4} borderColor="whiteAlpha.200" key={`order-${idx}`}>
            
            <GridItem colSpan={1} p={2}>
              <HStack>
                <Text color={side === OrderSide.Buy ? 'rgb(14, 203, 129)' : 'rgb(246, 70, 93)'} fontSize="sm">
                  { side === OrderSide.Buy ? 'Buy' : 'Sell' }
                </Text>
                {
                  (filled_amount * 1 < amount * 1) && (status !== OrderStatus.Canceled && status !== OrderStatus.PartialCanceled)  ?
                  <Button size="xs" isDisabled={isCanceling} onClick={() => onCancelOrder(id)}>Cancel</Button> : null
                }
              </HStack>
            </GridItem>
            <GridItem colSpan={2} p={2} textAlign="center">
              <Text color={gray} fontSize="sm">
                {DecimalUtil.beautify(DecimalUtil.fromString(filled_amount))} / {DecimalUtil.beautify(DecimalUtil.fromString(amount))}
              </Text>
            </GridItem>
            <GridItem colSpan={2} p={2} textAlign="center">
              <Text color={gray} fontSize="sm">${DecimalUtil.beautify(DecimalUtil.fromString(price))}</Text>
            </GridItem>
            <GridItem colSpan={2} p={2} textAlign="center">
              <Text color={gray} fontSize="sm">{dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')}</Text>
            </GridItem>
            <GridItem colSpan={1} p={2} textAlign="right">
              <Tag colorScheme={toOrderStatus(status).color} size="sm" variant="solid">
                {toOrderStatus(status).label}
              </Tag>
            </GridItem>
          </Grid>
        )) :
        <Center h="100%">
          <Text className="png-gray" opacity={.7}>You have no orders</Text>
        </Center>
      }
      </Box>
    </>
  );
}