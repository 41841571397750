import React, { useMemo } from 'react';
import axios from 'axios';

import {
  SimpleGrid,
  Box,
  Heading,
  Center,
  Tag,
  Flex,
  HStack,
  Text,
  List,
  Fade,
  useBoolean,
  Button,
  VStack,
  Container,
  useToast
} from '@chakra-ui/react';

import { Alert } from 'components';
import { DecimalUtil, base64ToHex } from 'utils';
import { chainConfig, API_HOST } from 'config';
import { useCommonStore } from 'stores';

type PositionProps = {
  contract: any;
  markets: any;
}

export const Position: React.FC<PositionProps> = ({ contract, markets }) => {

  const [closeAlertOpen, setCloseAlertOpen] = useBoolean();
  const [isClosing, setIsClosing] = useBoolean();
  const toast = useToast();

  const pl = useMemo(() => {
    if (!contract || !markets) {
      return 0;
    }

    const { position, side, avg_open_price } = contract;
    const { market_price } = markets;

    if (!(position * 1)) {
      return 0;
    }

    const percent = DecimalUtil.fromString(market_price).sub(DecimalUtil.fromString(avg_open_price)).mul(100).div(avg_open_price).toNumber();

    return side === 0 ? percent : -percent;

  }, [contract, markets]);

  const havePosition = useMemo(() => !!(contract?.position * 1), [contract]);

  const { common } = useCommonStore();

  const onClosePosition = async () => {
    setCloseAlertOpen.off();

    setIsClosing.on();

    try {
      const { address } = common.wallet;

      const { position, side } = contract;

      const toCloseSide = side === 1 ? 2 : 1;

      const price = side === 1 ? '0' : '99999999';

      const priceInU64Str = DecimalUtil.toU64(DecimalUtil.fromString(price), 5).toString();
      const sizeInU64Str = DecimalUtil.toU64(DecimalUtil.fromString(position), 5).toString();

      const timestamp = new Date().getTime();

      const symbol = 'BTCUSDT';

      const { signature } = await window.keplr.signArbitrary(
        chainConfig.chainId,
        common.wallet?.address,
        `${sizeInU64Str}${priceInU64Str}${timestamp}${symbol}${toCloseSide}`
      );

      const signatureInHex = base64ToHex(signature);

      await axios.post(`${API_HOST}/trade`, {
        address,
        symbol,
        side: toCloseSide,
        price,
        amount: position,
        timestamp,
        auth_sig: signatureInHex
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        position: 'top-right',
        description: err.toString(),
        status: 'error'
      });
    }

    setIsClosing.off();
  }

  return (
    <>
      <Center h="100%">
        <Container maxW="2xl">
          <SimpleGrid columns={2} gap={8}>
            <Box>
              <Box bg={
                !havePosition ?
                  'linear-gradient(342.62deg, #1b1a1c -9.23%, #303038 110.36%)' :
                  contract.side === 1 ?
                    'linear-gradient(342.62deg, #1b2c1c -9.23%, #303038 110.36%)' :
                    'linear-gradient(342.62deg, #2c1a1c -9.23%, #303038 110.36%)'
              } p={8} borderRadius="lg">
                <Flex justifyContent="space-between">
                  {
                    !havePosition ?
                      <Tag size="sm" colorScheme="gray" variant="solid">None</Tag> :
                      <Tag size="sm" colorScheme={contract.side === 1 ? 'rgb(14, 203, 129)' : 'rgb(246, 70, 93)'} variant="solid">
                        {contract.side === 1 ? 'Long' : 'Short'}
                      </Tag>
                  }
                  
                  {
                    havePosition ?
                    <Flex alignItems="flex-end" position="relative">
                      <Heading fontSize="md" fontWeight={600}>
                        {DecimalUtil.beautify(DecimalUtil.fromString(contract.position))} BTC
                      </Heading>
                      <Text className="png-gray" fontSize="xs" position="absolute" bottom="-18px" opacity={.7}>
                        ${DecimalUtil.beautify(DecimalUtil.fromString(contract.position).mul(contract.avg_open_price))}
                      </Text>
                    </Flex> : null
                  }
                  
                </Flex>
              </Box>
              <List mt={4} className="png-gray" fontSize="sm">
                <Flex justifyContent="space-between">
                  <VStack alignItems="flex-start">
                    <Text>Laverage</Text>
                    <Text>
                      {
                        havePosition ? '10x' : '-'
                      }
                    </Text>
                  </VStack>
                  <VStack alignItems="flex-end">
                    <Text>Profit / Loss</Text>
                    <HStack>
                      {
                        pl ?
                        <HStack>
                          <Heading fontSize="md" fontWeight={600}
                            color={pl === 0 ? '#a6a0bb' : pl < 0 ? 'rgb(246, 70, 93)' : 'rgb(14, 203, 129)'}>
                            {pl < 0 ? '-' : ''}${DecimalUtil.beautify(
                              DecimalUtil.fromString(contract.position).mul(contract.avg_open_price).mul(pl).div(100).abs()
                            )}
                          </Heading>
                          <Text className="png-gray">({DecimalUtil.fromNumber(pl).toFixed(2)}%)</Text>
                        </HStack> : <Text>-</Text>
                      }
                      
                    </HStack>
                  </VStack>
                </Flex>
              </List>
            </Box>
            <Flex flexDirection="column" justifyContent="space-between">
              <List className="png-gray" fontSize="sm">
                <Flex justifyContent="space-between" pt={2} pb={2} borderBottomWidth={1} borderColor="whiteAlpha.200">
                  <Text>Average Open</Text>
                  <Text>
                    {
                      havePosition ?
                        '$' + DecimalUtil.beautify(DecimalUtil.fromString(contract.avg_open_price)) : '-'
                    }
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" pt={2} pb={2} borderBottomWidth={1} borderColor="whiteAlpha.200">
                  <Text>Average Close</Text>
                  <Text>
                    {
                      havePosition ?
                        '$' + DecimalUtil.beautify(DecimalUtil.fromString(contract.avg_close_price)) : '-'
                    }
                  </Text>
                </Flex>
              </List>
              <Fade in={havePosition} onClick={setCloseAlertOpen.on}>
                <Button isFullWidth isDisabled={isClosing} isLoading={isClosing}>Close Position</Button>
              </Fade>
            
            </Flex>
          </SimpleGrid>
        </Container>
      </Center>
      <Alert message="Are you confrim to close your position?" isOpen={closeAlertOpen} 
        onClose={setCloseAlertOpen.off} title="Close Position" onConfirm={onClosePosition} confirmButtonColor="red" />
    </>
  );
}
