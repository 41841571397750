export * from './theme';

export const chainConfig = {
  chainId: 'perp-chain-1',
  chainName: 'Perp Chain Testnet',
  rpc: 'http://13.212.189.131:26657',
  rest: 'http://13.212.189.131:1317',
  stakeCurrency: {
    coinDenom: 'PERP',
    coinMinimalDenom: 'opn',
    coinDecimals: 6
  },
  bip44: {
    coinType: 118
  },
  bech32Config: {
    bech32PrefixAccAddr: 'cosmos',
    bech32PrefixAccPub: 'cosmospub',
    bech32PrefixValAddr: 'cosmosvaloper',
    bech32PrefixValPub: 'cosmosvaloperpub',
    bech32PrefixConsAddr: 'cosmoscons',
    bech32PrefixConsPub: 'cosmosconspub'
  },
  currencies: [{
    coinDenom: 'PERP',
    coinMinimalDenom: 'opn',
    coinDecimals: 6
  }],
  feeCurrencies: [{
    coinDenom: 'PERP',
    coinMinimalDenom: 'opn',
    coinDecimals: 6
  }]
}

export const API_HOST = process.env.REACT_APP_API_HOST || '/api';