import React, { useState, useMemo, useEffect } from 'react';

import {
  Flex,
  Box,
  Text,
  Button,
  useBoolean,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  useToast
} from '@chakra-ui/react';

import axios from 'axios';
import { OrderSide } from 'types';

import { chainConfig, API_HOST } from 'config';
import { OrderSideTab } from './OrderSideTab';
import { Laverage } from './Laverage';
import { base64ToHex, DecimalUtil, u8aToHex } from 'utils';
import { useCommonStore } from 'stores';
import useSWR from 'swr';

type PanelProps = {
  defaultPrice: string | undefined;
  defaultSize: string | undefined;
  defaultSide: OrderSide | undefined;
  contract: any;
}

export const Panel: React.FC<PanelProps> = ({ defaultPrice, defaultSize, defaultSide, contract }) => {
  const [side, setSide] = useState(OrderSide.Buy);
  const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [laverage, setLaverage] = useState<number>(10);

  const [isSubmiting, setIsSubmiting] = useBoolean();
  const [isDepositing, setIsDepositing] = useBoolean();

  const toast = useToast();
  const { common, updateAccount } = useCommonStore();

  useEffect(() => {
    if (defaultPrice) {
      setPrice(defaultPrice);
    }
    
  }, [defaultPrice]);

  useEffect(() => {
    if (defaultSize) {
      setSize(defaultSize);
    }
  }, [defaultSize]);

  useEffect(() => {
    if (defaultSide) {
      setSide(defaultSide);
    }
  }, [defaultSide]);

  const subBtnbg = useMemo(() => {
    return side === OrderSide.Buy ?
      'linear-gradient(to right, #6C9EFF, #09CA65 35%, #7BD2A2 70%, #FBFE7F)' :
      'linear-gradient(to right, #EF1111, #F93A6B 35%, #FF56A9 70%, #FF8388)';
  }, [side]);

  const onSideChange = (s: OrderSide) => {
    setSide(s);
  }

  const onSubmit = async () => {
    setIsSubmiting.on();

    try {
      const { address } = common.wallet;

      await checkAccount();

      const priceInU64Str = DecimalUtil.toU64(DecimalUtil.fromString(price), 5).toString();
      const sizeInU64Str = DecimalUtil.toU64(DecimalUtil.fromString(size), 5).toString();

      const timestamp = new Date().getTime();

      const symbol = 'BTCUSDT';

      const { signature } = await window.keplr.signArbitrary(
        chainConfig.chainId,
        common.wallet?.address,
        `${sizeInU64Str}${priceInU64Str}${timestamp}${symbol}${side}`
      );

      const signatureInHex = base64ToHex(signature);

      await axios.post(`${API_HOST}/trade`, {
        address,
        symbol,
        side,
        price,
        amount: size.toString(),
        timestamp,
        auth_sig: signatureInHex
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        position: 'top-right',
        description: err.toString(),
        status: 'error'
      });
    }

    setIsSubmiting.off();
  }

  const checkAccount = async () => {
    if (!common.account) {
      const { address, pubkey } = common.wallet;
      const name = 'test01';
      const pubkeyInHex = u8aToHex(pubkey);

      const { signature } = await window.keplr.signArbitrary(
        chainConfig.chainId,
        common.wallet?.address,
        `${name}${address}create-account`
      );

      const signatureInHex = base64ToHex(signature);

      await axios.post(`${API_HOST}/create-account`, {
        name,
        address,
        pub_key: pubkeyInHex,
        auth_sig: signatureInHex
      }).catch(err => null);

      updateAccount({ address });
    }
  }

  const onDeposit = async () => {
    setIsDepositing.on();
 
    try {
      const { address } = common.wallet;
      
      await checkAccount();

      const amount = '10000';
      const symbol = 'BTCUSDT';

      const { signature } = await window.keplr.signArbitrary(
        chainConfig.chainId,
        common.wallet?.address,
        `${symbol}${amount}deposit`
      );

      const signatureInHex = base64ToHex(signature);

      await axios.post(`${API_HOST}/deposit`, {
        address,
        symbol,
        amount,
        auth_sig: signatureInHex
      });
    } catch (err: any) {
      toast({
        title: 'Error',
        position: 'top-right',
        description: err.toString(),
        status: 'error'
      });
    }

    setIsDepositing.off();
  }

  const avaiable = useMemo(() => {
    if (!contract) {
      return 0;
    }

    const { position, margin, avg_open_price } = contract;

    if (!(position * 1)) {
      return DecimalUtil.fromString(margin).toNumber();
    }

    return DecimalUtil.fromString(margin).sub(
      DecimalUtil.fromString(position).mul(avg_open_price).div(10)
    ).toNumber();

  }, [contract]);

  const maxAmount = useMemo(() => {
    if (!avaiable || !price) {
      return 0;
    }

    return DecimalUtil.fromNumber(avaiable).mul(laverage).div(
      DecimalUtil.fromString(price)
    ).toNumber();

  }, [avaiable, price, laverage]);

  return (
    <Flex p={4} h="100%" flexDirection="column">
      <OrderSideTab side={side} onSideChange={onSideChange} />
      <Box mt={5}>
        <Laverage onChange={setLaverage} value={laverage} />
      </Box>
      <Box mt={5}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack className="png-gray" fontSize="sm">
            <Text>Order</Text>
            {/* <Text opacity={.7} fontSize="xs">Set order</Text> */}
          </HStack>
          <HStack>
            <Text className="png-gray" fontSize="xs">Avaiable</Text>
            <Text fontSize="sm">
              {avaiable ? '$' + DecimalUtil.beautify(DecimalUtil.fromNumber(avaiable)) : '-'}
            </Text>
            {
              common?.wallet ?
              <Button size="xs" colorScheme="green" variant="outline" onClick={onDeposit} 
                isLoading={isDepositing} isDisabled={isDepositing}>
                Deposit
              </Button> : null
            }
          </HStack>
        </Flex>
        <HStack mt={3}>
          <InputGroup>
            <Input
              placeholder="Price"
              type="number"
              fontSize="md"
              fontWeight={700}
              borderRadius="lg"
              border="none"
              value={price}
              onChange={e => setPrice(e.target.value)}
              bg="whiteAlpha.100"
              _focus={{ bg: 'whiteAlpha.200' }} />
            <InputRightElement mr={2} w="3.5rem" justifyContent="flex-end">
              <Tag size="sm">USDT</Tag>
            </InputRightElement>
          </InputGroup>
        </HStack>
        <HStack mt={3}>
          <InputGroup>
            <Input
              placeholder="Size"
              type="number"
              fontSize="md"
              fontWeight={700}
              borderRadius="lg"
              border="none"
              value={size}
              onChange={e => setSize(e.target.value)}
              bg="whiteAlpha.100"
              _focus={{ bg: 'whiteAlpha.200' }} />
            <InputRightElement mr={2} w="3.5rem" justifyContent="flex-end">
              <Tag size="sm">BTC</Tag>
            </InputRightElement>
          </InputGroup>
        </HStack>
      </Box>
      <Flex flex={1} flexDirection="column" justifyContent="flex-end" pb={6}>
        <Box p={4} bg="rgba(0, 0, 0, .3)" borderRadius="lg">
          <Flex justifyContent="space-between" alignItems="center" fontSize="sm">
            <Text className="png-gray">Max {side === OrderSide.Buy ? 'Buy' : 'Sell'}</Text>
            <Text>
              {
                maxAmount ? 
                DecimalUtil.beautify(
                  DecimalUtil.fromNumber(maxAmount)
                ) + 'BTC' : '-'
              }
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mt={2} fontSize="sm">
            <Text className="png-gray">Cost</Text>
            <Text>
              {
                price && size ?
                  '$' + DecimalUtil.beautify(
                    DecimalUtil.fromString(price).mul(DecimalUtil.fromString(size)).div(laverage)
                  ) : '-'
              }
            </Text>
          </Flex>
          <Button isFullWidth mt={3} bg={subBtnbg} color="black"
            isDisabled={
              !price || !size || !common.wallet || isSubmiting ||
              (size as any) * 1 > maxAmount
            }
            _hover={{
              bg: subBtnbg, transform: 'scale(1.01)'
            }}
            isLoading={isSubmiting}
            onClick={onSubmit}>
            {
              !common.wallet ?
                'Connect Wallet' :
                (size as any) * 1 > maxAmount ?
                'Max Limit' :
                side === OrderSide.Buy ? 'Buy/Long' : 'Sell/Short'
            }
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
}
