import {
  ChakraProvider
} from '@chakra-ui/react';

import { theme } from 'config';

import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate 
} from 'react-router-dom';

import {
  Root,
  Trade
} from 'views';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path="" element={<Navigate to="trade" />} />
          <Route path="trade" element={<Trade />} />
        </Route>
      </Routes>
    </Router>
  </ChakraProvider>
)
