import React from 'react';

import {
  Grid,
  Skeleton,
  Image,
  Box,
  HStack,
  Heading,
  Flex,
  Text,
  VStack,
  Divider,
  GridItem
} from '@chakra-ui/react';

import { TriangleDownIcon } from '@chakra-ui/icons';
import btc from 'assets/btc.jpg';
import { DecimalUtil } from 'utils';

type MarketProps = {
  data: any;
  newTrade: any;
}

export const Market: React.FC<MarketProps> = ({ data: markets, newTrade }) => {

  return (
    <Grid templateColumns="repeat(16, 1fr)">
      <GridItem colSpan={3}>
        <Flex p={3} justifyContent="space-between" alignItems="center" h="100%">
          <HStack>
            <Box boxSize={6} overflow="hidden" borderRadius="full">
              <Image src={btc} w="100%" h="100%" />
            </Box>
            <Heading fontSize="lg">BTC-USDT</Heading>
          </HStack>
          <HStack className="png-gray">
            <Text fontSize="sm">All markets</Text>
            <TriangleDownIcon boxSize={2} ml={1} />
          </HStack>
        </Flex>
      </GridItem>
      <GridItem colSpan={13}>
        <Flex pt={3} pb={3} h="100%">
          <HStack spacing={3}>
            <Divider orientation="vertical" />
            <Skeleton isLoaded={!!newTrade}>
              <Heading fontSize="xl" color={!newTrade ? 'white' : newTrade.is_buyer_maker ? 'rgb(14, 203, 129)' : 'rgb(246, 70, 93)'}>
                {
                  newTrade ?
                    '$' + DecimalUtil.beautify(DecimalUtil.fromString(newTrade.price)) : 'loading'
                }
              </Heading>
            </Skeleton>
            <Divider orientation="vertical" />
            <VStack spacing={0} alignItems="flex-start" className="png-gray">
              <Text fontSize="xs" opacity={.7}>Index Price</Text>
              <Text fontSize="sm">
                { markets ? '$'  + DecimalUtil.beautify(DecimalUtil.fromString(markets.market_price)) : '-' }
              </Text>
            </VStack>
            <Divider orientation="vertical" />
            {/* <VStack spacing={0} alignItems="flex-start" className="png-gray">
              <Text fontSize="xs" opacity={.7}>24h Change</Text>
              <HStack spacing={0}>
                <TriangleUpIcon boxSize={2} mr={1} />
                <Text fontSize="sm">$354.03 (0.79%)</Text>
              </HStack>
            </VStack> */}
            <VStack spacing={0} alignItems="flex-start" className="png-gray">
              <Text fontSize="xs" opacity={.7}>24h Volume</Text>
              <Text fontSize="sm">
                { markets ? '$'  + DecimalUtil.beautify(DecimalUtil.fromString(markets.trades_volume)) : '-' }
              </Text>
            </VStack>
            
            
          </HStack>
        </Flex>
      </GridItem>
    </Grid>
  )
}