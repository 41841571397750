import React from 'react';

import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Flex,
  Box,
  VStack,
  Text,
  Image,
  useColorModeValue
} from '@chakra-ui/react';

import { BaseModal, BaseModalProps } from './BaseModal';
import { chainConfig } from 'config';
import keplrLogo from 'assets/keplr-icon.jpg';

export const ConnectWalletModal: React.FC<BaseModalProps> = (props) => {
  const gray = useColorModeValue('#868099', '#a6a0bb');

  const onConnectWallet = async () => {
    // init kplr wallet
    if (!window.keplr || !window.getOfflineSigner) {
      return;
    }

    await window.keplr.experimentalSuggestChain(chainConfig);

    await window.keplr.enable(chainConfig.chainId);
    window.location.reload();
  }

  return (
    <BaseModal {...props}>
      <ModalHeader>
        Connect Wallet
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box bg="black" p={4} borderRadius="lg" cursor="pointer" _hover={{
          background: '#0b0a0c'
        }} onClick={onConnectWallet}>
          <Flex justifyContent="space-between" alignItems="center">
            <VStack spacing={1} alignItems="flex-start">
              <Heading fontSize="lg">Keplr Wallet</Heading>
              <Text color={gray}>keplr.app</Text>
            </VStack>
            <Box w="42px" borderRadius="lg" overflow="hidden">
              <Image src={keplrLogo} w="100%" h="auto" />
            </Box>
          </Flex>
        </Box>
        <Box pb={6} />
      </ModalBody>
    </BaseModal>
  );
}