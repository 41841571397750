import create from 'zustand';

type CommonStore = {
  common: any;
  updateWallet: (wallet: any) => void;
  updateAccount: (account: any) => void;
  clearAccount: () => void;
}

const storedAccount = window.localStorage.getItem('account');

export const useCommonStore = create((set: any, get: any): CommonStore => ({
  common: {
    account: storedAccount ? JSON.parse(storedAccount) : null,
    wallet: null
  },

  updateWallet: (wallet: any) => {
    const common = get().common;
    set({ common: { ...common, wallet } });
  },

  updateAccount: (account: any) => {
    const common = get().common;
    set({ common: { ...common, account } });
    window.localStorage.setItem('account', JSON.stringify(account));
  },

  clearAccount: () => {
    set({ common: { account: null }});
    window.localStorage.removeItem('account');
  }

}));