import React, { useEffect, useMemo, useState } from 'react';
import * as klineCharts from 'klinecharts';
import useSWR from 'swr';

import {
  Box,
  Center,
  Spinner,
  HStack,
  Flex,
  useColorModeValue
} from '@chakra-ui/react';

import { DecimalUtil } from 'utils';

function generateData(baseTimestamp = Date.now(), basePrice = 37670, dataSize = 800, interval = 60) {
  const dataList = []
  let timestamp = Math.floor(baseTimestamp / 60 / 1000) * 60 * 1000
  let baseValue = basePrice
  const prices = []
  for (let i = 0; i < dataSize; i++) {
    baseValue = baseValue + Math.random() * 20 - 10
    for (let j = 0; j < 4; j++) {
      prices[j] = (Math.random() - 0.5) * 12 + baseValue
    }
    prices.sort()
    const openIdx = +Math.round(Math.random() * 3).toFixed(0)
    let closeIdx = +Math.round(Math.random() * 2).toFixed(0)
    if (closeIdx === openIdx) {
      closeIdx++
    }
    const volume = (interval / 60) * Math.random() * 18 + 0.1
    const kLineModel = {
      open: prices[openIdx],
      low: prices[0],
      high: prices[3],
      close: prices[closeIdx],
      volume: volume,
      timestamp,
      turnover: 0
    }
    timestamp -= interval * 1000;
    kLineModel.turnover = (kLineModel.open + kLineModel.close + kLineModel.high + kLineModel.low) / 4 * volume;
    dataList.unshift(kLineModel);
  }
  return dataList
}

// const intervals = [60, 60 * 15, 3600, 4 * 3600, 24 * 3600, 7 * 24 * 3600];

type Interval = {
  seconds: number;
  label: string;
  id: number;
}

const intervals: Interval[] = [{
  seconds: 60,
  label: '1m',
  id: 1
}, {
  seconds: 60 * 15,
  label: '15m',
  id: 2
}, {
  seconds: 3600,
  label: '1h',
  id: 4
}, {
  seconds: 4 * 3600,
  label: '4h',
  id: 5
}, {
  seconds: 24 * 3600,
  label: '1d',
  id: 6
}, {
  seconds: 7 * 24 * 3600,
  label: '1w',
  id: 7
}];

export const Chart: React.FC = () => {

  const gridColor = useColorModeValue('#292929', '#292929');
  const textColor = useColorModeValue('#a6a0bb', '#a6a0bb');
  const axisLineColor = useColorModeValue('#333', '#333');
  const crossTextBackgroundColor = useColorModeValue('#373a40', '#373a40');

  const [interval, setInterval] = useState(intervals[0]);

  const { data: klineData } = useSWR(`klines?symbol=BTCUSDT&interval=${interval.id}`);
  const [chartInstance, setChartInstance] = useState<klineCharts.Chart>();

  const [fakeData, setFakeData] = useState<any[]>([]);

  const realKlineData = useMemo(() => {
    if (!klineData?.length) {
      return [];
    }

    const tmpArr = [];
    for (let i = 0; i < klineData.length; i++) {
      const { close, high, low, open, open_time, volume } = klineData[i];

      const closeInDecimal = DecimalUtil.fromString(close);
      const highInDecimal = DecimalUtil.fromString(high);
      const lowInDecimal = DecimalUtil.fromString(low);
      const openInDecimal = DecimalUtil.fromString(open);
      const volumeInDecimal = DecimalUtil.fromString(volume);

      if (openInDecimal.eq(0) && volumeInDecimal.eq(0)) {
        continue;
      }

      const kLineModel = {
        open: openInDecimal.toNumber(),
        low: lowInDecimal.toNumber(),
        high: highInDecimal.toNumber(),
        close: closeInDecimal.toNumber(),
        volume: volumeInDecimal.toNumber(),
        timestamp: open_time,
        turnover: openInDecimal.add(closeInDecimal).add(highInDecimal).add(lowInDecimal).div(4).mul(volumeInDecimal).toNumber()
      }

      tmpArr.push(kLineModel);

    }

    return fakeData.concat(tmpArr);
  }, [klineData, fakeData]);

  useEffect(() => {
    setFakeData(generateData(Date.now(), 37670, 500, interval.seconds));
  }, [interval]);

  useEffect(() => {

    const chart = klineCharts.init('kline_chart');

    if (!chart) {
      return;
    }

    chart.createTechnicalIndicator('MA', false, { id: 'candle_pane' });
    chart.createTechnicalIndicator('VOL');

    chart.setStyleOptions({
      grid: {
        horizontal: { 
          style: 'solid', 
          color: gridColor 
        },
        vertical: { 
          style: 'solid',
          color: gridColor 
        }
      },
      candle: {
        priceMark: {
          high: { color: textColor },
          low: { color: textColor },
          last: {
            upColor: 'rgb(14, 203, 129)',
            downColor: 'rgb(246, 70, 93)'
          }
        },
        tooltip: {
          text: { color: textColor },
          labels: ['T: ', 'O: ', 'C: ', 'H: ', 'L: ', 'V: ']
        },
        bar: {
          upColor: 'rgb(14, 203, 129)',
          downColor: 'rgb(246, 70, 93)'
        }
      },
      technicalIndicator: {
        tooltip: {
          text: { color: textColor }
        },
        bar: {
          upColor: 'rgb(14, 203, 129, .5)',
          downColor: 'rgb(246, 70, 93, .5)'
        }
      },
      xAxis: {
        axisLine: { color: axisLineColor },
        tickLine: { color: axisLineColor },
        tickText: { color: textColor },
      },
      yAxis: {
        axisLine: { color: axisLineColor },
        tickLine: { color: axisLineColor },
        tickText: { color: textColor },
      },
      separator: { color: axisLineColor },
      crosshair: {
        horizontal: {
          line: { color: axisLineColor },
          text: { backgroundColor: crossTextBackgroundColor }
        },
        vertical: {
          line: { color: axisLineColor },
          text: { backgroundColor: crossTextBackgroundColor }
        }
      }
    });

    chart.zoomAtCoordinate(1.5);

    window.addEventListener('resize', () => {
      chart.resize();
    });

    setChartInstance(chart);

    return () => {
      if (chart) {
        klineCharts.dispose(chart);
      }
    }
  }, []);

  useEffect(() => {
    if (!chartInstance || !realKlineData?.length) {
      return;
    }
    chartInstance.applyNewData(realKlineData);
  }, [chartInstance, realKlineData]);

  return (
    <Box h="100%" position="relative">
      <Flex borderBottomWidth={1} borderColor="whiteAlpha.200" p="8px" justifyContent="flex-end">
        <HStack fontSize="xs" spacing={0}>
          {
            intervals.map((i, idx) => (
              <Box pl={2} pr={2} color={ interval.seconds === i.seconds ? 'rgb(240, 185, 11)' : '#a6a0bb' } 
                cursor="pointer" key={`interval-${idx}`} onClick={() => setInterval(intervals[idx])}
                _hover={{ color: 'rgb(240, 185, 11)' }}>{ i.label }</Box>
            ))
          }
        </HStack>
      </Flex>
      <Box id="kline_chart" h="calc(100% - 35px)" />
      {
        !realKlineData?.length ?
        <Center position="absolute" top={0} left={0} right={0} bottom={0} zIndex={9999}>
          <Spinner thickness="3px" speed="1s" color="gray.500" />
        </Center> : null
      }
    </Box>
    
  );
}