import React, { useMemo } from 'react';

import {
  Flex,
  Box,
  Heading,
  Center
} from '@chakra-ui/react';

import {
  useSpring,
  animated,
  config as SpringConfig
} from 'react-spring';

import { OrderSide } from 'types';

type OrderSideTabProps = {
  side: OrderSide;
  onSideChange: (s: OrderSide) => void;
}

export const OrderSideTab: React.FC<OrderSideTabProps> = ({ side, onSideChange }) => {

  const bg = useMemo(() => {
    return side === OrderSide.Buy ?
      'linear-gradient(to right, #6C9EFF, #09CA65 35%, #7BD2A2 70%, #FBFE7F)' :
        'linear-gradient(to right, #EF1111, #F93A6B 35%, #FF56A9 70%, #FF8388)';
  }, [side]);

  const animBoxProps = useSpring({
    from: { left: '0%' },
    to: { left: side === OrderSide.Buy ? '50%' : '0%' },
    config: SpringConfig.slow
  });

  return (
    <Flex bg="rgba(0, 0, 0, .3)" borderRadius="lg" position="relative">
      <Center flex={1} p={2} cursor="pointer" onClick={() => onSideChange(OrderSide.Sell)} zIndex={2}>
        <Heading fontSize="md">Sell</Heading>
      </Center>
      <Center flex={1} p={2} cursor="pointer" onClick={() => onSideChange(OrderSide.Buy)} position="relative" zIndex={2}>
        <Heading fontSize="md">Buy</Heading>
      </Center>
      <animated.div
        style={{
          position: 'absolute',
          bottom: 0,
          top: 0,
          zIndex: 0,
          height: 'auto',
          width: '50%',
          ...animBoxProps
        }}>
        <Box borderRadius="lg" overflow="hidden" top="0" bottom="0" w="100%" h="100%" bg={bg} position="absolute">
          <Box top="1px" bottom="1px" left="1px" right="1px" w="auto" h="auto" bg="black" position="absolute" zIndex={0} borderRadius="lg" />
          <Box top="1px" bottom="1px" left="1px" right="1px" w="auto" h="auto" bg={bg} position="absolute" opacity={.2} zIndex={1} />
        </Box>

      </animated.div>
    </Flex>
  );
}